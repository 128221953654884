<template>
	<div class="iFrame-container">
		<iframe
			ref="iframe"
			:src="model.iframeUrl"
			:title="model.title"
			:width="model.width"
			:height="model.height"
			scrolling="no"
			frameborder="0"
			onload="iFrameResize()"
		>
		</iframe>
	</div>
</template>

<script setup lang="ts">
	import { IIFrameBlock } from '@/types/block';
	import { onMounted, ref } from 'vue';

	defineProps<{
		model: IIFrameBlock;
		storybook?: boolean;
	}>();

	const iframe = ref<HTMLIFrameElement | null>(null);

	onMounted(() => {
		window.addEventListener(
			'message',
			function (e) {
				const message = e.data;
				if (iframe.value && message.height && message.width) {
					iframe.value.style.height = message.height + 'px';
					// iframe.value.style.width = message.width + 'px';
				}
			},
			false
		);
	});
</script>

<style lang="scss" scoped>
	@import './BatgirlIFrameBlock.module.scss';
</style>
